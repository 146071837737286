<template>
  <div class="lg:w-3/4 md:w-5/6 sm:w-full mx-auto">

    <!-- Warn users of skipping selfie modal -->
    <div v-if="isSkipSelfieModalVisible" class="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
      <div class="bg-white p-8 rounded-lg shadow-lg w-3/4">
        <div class="flex items-center">
          <!-- <span class="text-3xl pr-4"></span> -->
          <svg class="mr-4 fill-current text-red-600" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.5 6h3l-1 8h-1l-1-8zm1.5 12.25c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25z"/></svg>
          <h2 class="text-xl md:text-2xl font-bold text-red-600 leading-tight">Are you sure you want to skip?</h2>
        </div>
        <p class="mt-4 text-lg">We recommend taking a selfie to help us find your special moment walking across the stage. If you are searching for photos on behalf of a graduate, please skip.</p>
        <p class="mt-4 text-sm">The selfie you take is instantly deleted after we have found all your award photos on stage.</p>
        <div class="mt-6 flex justify-between md:justify-start md:gap-3 ">
          <button @click="$emit('skip')" class="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-700">
            Skip selfie
          </button>
          <button @click="controlSkipSelfieModal(false)" class="bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700">
            <b>Take selfie</b>
          </button>
        </div>
      </div>
    </div>

    <div class="flex flex-col items-center">
      <div class="text-center mt-4 mx-auto max-w-7xl px-4 sm:mt-2 sm:px-6">
        <h2 class="text-2xl tracking-tight font-extrabold text-gray-900">
          {{ name }}
        </h2>
        <h2 class="text-xl font-bold text-gray-900 whitespace-nowrap">
          Take a selfie!
        </h2>
        <p class="mt-2 text-gray-700">
          To help find your images, take a selfie (or choose to skip).
        </p>
        <p class="mt-2 text-gray-700">
          Don't worry, we do not save this image - we delete it immediately
          after searching.
        </p>
      </div>
      <div class="camera-container">
        <div class="mt-4" v-if="!imageCaptured">
          <button
            @click="controlSkipSelfieModal(true)"
            class="bg-red-600 hover:bg-red-700 text-md"
          >
            <b> Skip taking a selfie</b>
          </button>
        </div>
        <div class="mt-2 button-container" v-if="imageCaptured">
        <button
            @click="retake"
            class="bg-red-600 hover:bg-red-700 text-md"
          >
            <b>Retake</b>
          </button>
        
        </div>

        <video ref="video" v-show="!imageCaptured" autoplay playsinline></video>

        <canvas ref="canvas" style="display: none"></canvas>

        <img v-if="imageCaptured" :src="capturedImage" alt="Captured selfie" />
        <div
          class="mt-2 sticky bottom-4 left-1/2 transform -translate-x-1/2 z-50"
        >
          <button
            v-if="!imageCaptured"
            @click="captureSelfie"
            class="bg-green-600 hover:bg-green-700"
            :disabled="isSkipSelfieModalVisible"
          >
            <b>Capture Selfie</b>
          </button>
          <button v-else
            @click="$emit('search-face', capturedImage)"
            class="search-button bg-green-600 hover:bg-green-700"
          >
            <b> Continue</b>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchByFace",
  data() {
    return {
      stream: null,
      imageCaptured: false,
      capturedImage: null,
      isSkipSelfieModalVisible: false,
    };
  },
  mounted() {
    this.startCamera();
  },
  beforeDestroy() {
    this.stopCamera();
  },
  methods: {
    async startCamera() {
      try {
        this.stream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: "user" },
          audio: false,
        });
        this.$refs.video.srcObject = this.stream;
      } catch (error) {
        console.error("Error accessing camera:", error);
      }
    },
    stopCamera() {
      if (this.stream) {
        this.stream.getTracks().forEach((track) => track.stop());
      }
    },
    captureSelfie() {
      const video = this.$refs.video;
      const canvas = this.$refs.canvas;
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      const context = canvas.getContext("2d");
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      this.capturedImage = canvas.toDataURL("image/jpeg");
      this.imageCaptured = true;
      this.stopCamera();
    },
    retake() {
      this.imageCaptured = false;
      this.capturedImage = null;
      this.startCamera();
    },
    controlSkipSelfieModal(bool){
      this.isSkipSelfieModalVisible = bool;
    }
  },
};
</script>

<style scoped>
.camera-container {
  display: flex;
  padding: 0 0;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

video,
img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 8px;
}

button {
  padding: 8px 16px;
  border-radius: 4px;
  color: white;
  border: none;
  cursor: pointer;
}

.skip {
  padding: 8px 16px;
  border-radius: 4px;
  color: white;
  background-color: unset;
  border: none;
  cursor: pointer;
}
</style>